.slides {
    padding: 0;
}
.slides>li {
    display: none;
    margin: 0;
}
.slides img {
    max-width: 100%;
    display: block;
}
.flex-pauseplay span {
    text-transform: capitalize;
}

.slides:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}
html[xmlns] .slides {
    display: block;
}
* html .slides {
    height: 1%;
}
.no-js .slides>li:first-child {
    display: block;
}
.flexslider {
	margin:0;
	padding:0;
	position: relative;
	zoom: 1;
}
body.flexdemobody .flexslider {
    min-height: 170px;
}
.flexslider .slides {
	margin:0;
	padding:0;
    zoom: 1;
}
.flexslider .slides>li {
    position: relative;
}
.flex-container {
    zoom: 1;
    position: relative;
}
/*for deracton ctrl*/
.flex-direction-nav {
    *height: 0;
    padding: 0;
	display:none;
}

.flex-direction-nav li a {
    width: 26px;
    height: 26px;
    margin: -13px 0 0;
    display: block;
    font-size: 0;
    position: absolute;
    top: 45%;

    cursor: pointer;
    color: #666;
    opacity: .9;
    filter: alpha(opacity=@opacity * 100);
}

.flex-direction-nav li a:hover {
    color: #4d4d4d;
}

.flex-direction-nav li .flex-next:before,.flex-direction-nav li .flex-prev:before {
    font-family: WebSymbolsRegular;
    font-size: 20px;
}

.flex-direction-nav li .flex-next {
    background-position: -30px 0;
    right: -37px;
}

.flex-direction-nav li .flex-prev {
    left: -37px;
}

.flex-direction-nav li .flex-next:before {
    content: ')';
}

.flex-direction-nav li .flex-prev:before {
    content: '(';
}

.flex-direction-nav li .disabled {
    opacity: .3;
    filter: alpha(opacity=30);
    cursor: default;
}



