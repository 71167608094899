a {
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}
img{
	border:none;
	max-width:100%;
	}
body{
	margin:0;
	padding:0;
	text-align:center;
	color:#7b7867;
	font-size:18px;
	overflow-x:hidden;
	}
	a.navbar-brand img {
  width: 250px;
}
.gallerywrapp img {
  max-width: 510px;
}
.categorygallerys li {
  float: left;
  list-style: none;
  width: 33%;
  height:291px;
}
.gallerywrapp li {
  float: left;
  list-style: none;
  width: 50%;
}
.categorygallerys li img{
width:100%;
  max-height: 235px;
}
.clear{
	clear:both;
	}
.contener{
	margin:0 auto;
	max-width:1000px;
	text-align:left;
	position:relative;
	}
.logo{
	display:inline-block;
	margin:10px 0;
	}
.banner img{
	width:100%;
	height:auto;
	display:block;
	}
.container {
  text-align: left;
}
.content{
	padding:20px 0;
	position:relative;
	}
.slider_panel{
	margin:10px 0;
	padding:10px 0px 5px 80px;	
	}
.slider_panel ul{
	margin:0px;
	padding:0;
	list-style:none;
	}
.slider_panel ul li{
	margin:10 13px;
	padding:0;
	height:80px;
	width:100px;
	display:inline-block;
	border-radius:5px;
	overflow:hidden;
	}
.slider_panel ul li a{
	display:table-cell;
	vertical-align:middle;
	text-align:center;
	width:100px;
	height:80px;
	}
.slider_panel ul li img{
	margin:0 auto;
	display:block;
	max-width:100%;
	max-height:100%;
	}
	
/*--------------------Footer*/
.footer{
  background-color: #3B4348;
	padding:20px 0;
	font-size:12px;
	  color: #FFF;
	}
	.socialfooter {
  font-size: 25px;
}
.socialfooter a {
  color:#fff;
}
/*------------------------------Subpage*/
.error{
	background-image:url(../images/error_img.png) !important;
	background-position:97% center !important;
	background-repeat:no-repeat !important;
	border:1px solid #F00 !important;
	margin:5px 0 !important;
	box-shadow:0 0 15px rgba(168, 0, 0, 0.7);
	}
	
.butn_1{
	border:none;
	background:none;
	padding:0;
	margin:0 auto;	
	cursor:pointer;
	}
.butn_1 img{
	display:block;
	}
.butn_1:hover{
	box-shadow:0 0 7px #FB02F3;
	}
/*------------------------------Topheader*/	
	.top-header {
  background: #3B4348;
  position: relative;
  margin: 0;
    color: #B5B5B5;
  padding: 21px 0 22px 0;
}
.top-header-content {
  width: 90%;
  margin: 0 auto;
}
.top-header-email {
  display: inline-block;
  margin-right: 20px;
}
.top-header-phone {
  display: inline-block;
  margin-right: 20px;
}
.top-header-address {
  display: inline-block;
}
.top-header-left {
  width: 75%;
  display: inline-block;
  text-align: left;
}
.top-header-right {
  width: 24%;
  display: inline-block;
  text-align: right;
    font-size: 20px;
}
.navbar>.container .navbar-brand, .navbar>.container-fluid .navbar-brand {
  height: 100%;
}
nav#navbarstick {
  width: 100%;
  opacity: 1;
  z-index: 999999;
  margin-bottom: 0;
}
.bannertext {
  z-index: 99;
  font-size: 35px;
  color: #FFF;
  position: absolute;
  top: 55px;
  left: 65px;
}
.glyphicon {
  margin-right: 5px;
}
.top-header-right a {
color:white;	
margin-right: 4px;
}
.top-header-right a:hover {
  margin: -3px 0 0 0;
  padding: 0 0 3px 0;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  color: #D7D1D1;
}
/* contact form*/
.cont_form {
  width: 50%;
  float: right;
}
.cont_address {
  float: left;
  width: 50%;
}
.fixednav {
}
html form button, html input, html select, html textarea {
  width: 100%;
  margin-top: 10px;
}

.serviceimage {
  float: left;
  max-width: 300px;
}
.serviceimage img{
  float: left;
  max-width: 300px;
}
.scrollToTop{
	  width: 42px;
  height: 42px;
  margin: 0;
  -webkit-border-radius: 42px;
  -moz-border-radius: 42px;
  border-radius: 42px;
	padding:10px; 
	text-align:center; 
	
	font-weight: bold;
	color: #444;
	text-decoration: none;
	position:fixed;
	right: 10px;
  bottom: 10px;
	display:none;
background: #1E2731 url(../img/up-arrow.png) no-repeat 50% 50%;
}
.frontblogimg img {
  max-width: 200px;
  max-height: 200px;
}
.blogimage {
  float: left;
  width: 250px;
}
.blogimage img{
  float: left;
  max-width: 250px;
  max-height: 250px;
}
.scrollToTop:hover{
	text-decoration:none;
}
@media only screen 

and (min-device-width : 320px) 

and (max-device-width : 480px) {

/* Smartphones (portrait and landscape) ----------- */


.bannertext {
  position: static;
  color: #000;
}
a.navbar-brand {
  width: 75%;
  float: left;
}
.top-header-right {
  width: 100%;
  text-align: left;
  margin-top: 15px;
}
.top-header-left {
  width: 100%;
}
.cont_address {
	width:100%;
	}
	.cont_form {
		width:100%;
		}
}



@media only screen 

and (min-width : 321px) {

/* Smartphones (landscape) ----------- */



}





@media only screen 

and (max-width : 320px) {

/* Smartphones (portrait) ----------- */





}





@media only screen 

and (min-device-width : 768px) 

and (max-device-width : 1024px) {

/* iPads (portrait and landscape) ----------- */



}





@media only screen 

and (min-device-width : 768px) 

and (max-device-width : 1024px) 

and (orientation : landscape) {

/* iPads (landscape) ----------- */



}





@media only screen 

and (min-device-width : 768px) 

and (max-device-width : 1024px) 

and (orientation : portrait) {

/* iPads (portrait) ----------- */





}



@media only screen 

and (min-width : 1200px) {

/* Desktops and laptops ----------- */



}



@media only screen 

and (min-width : 1700px) {

/* Large screens ----------- */



}



@media

only screen and (-webkit-min-device-pixel-ratio : 1.5),

only screen and (min-device-pixel-ratio : 1.5) {

/* iPhone 4 ----------- */



}